@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.eot');
    src: url('Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('Gotham-Bold.woff2') format('woff2'),
        url('Gotham-Bold.woff') format('woff'),
        url('Gotham-Bold.ttf') format('truetype'),
        url('Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('Gotham-Book.eot');
    src: url('Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('Gotham-Book.woff2') format('woff2'),
        url('Gotham-Book.woff') format('woff'),
        url('Gotham-Book.ttf') format('truetype'),
        url('Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

