#home{width: 100%;height: 100%;position: relative;overflow: hidden;}
.video{width: 100%; height: 95vh;}
.content{width: 100%; position: absolute; top: 83%;color: #fff;z-index: 2; text-align: center;}
.content button{background: #ef5599; color: #fff;border: 0; border-radius: 50px; padding: 10px; 
  text-transform: uppercase; font-size: 15px; cursor: pointer;}
.content button:hover{background: #b32765;}
.bg{background: url('../../assets/form-back.png') top / 100% no-repeat; height: 160px; position: relative; z-index: 1;}
#form-content{color: #fff; padding: 90px 0px 30px 0px; background: #33c3f1; }
#form-content p{text-align: center; font-size: 20px; padding-bottom: 20px; max-width: 930px; margin: 0 auto;}
.form{max-width: 780px; margin: 0 auto; padding: 30px 0px;}
.form div{margin-bottom: 35px;}
.form input, .form select{width: 100%; background: transparent; color:#ffffff; border-radius: 50px;
border: 1px solid #fff; padding: 10px; font-size: 16px;}
select{text-transform: uppercase;}
select option{background: #33c3f1;}
.half{display: grid; grid-template-columns: 1fr 1fr; gap: 10px;}
.form button{width: 140px; background: #f9a962; color: #fff;border: 0; border-radius: 50px; padding: 10px; 
  text-transform: uppercase; font-size: 20px; cursor: pointer;}
.form button:hover{background: #ce772b;}

@media (max-width: 1340px){
#video{height: 80vh;}
#form-content{padding: 0px;}
}

@media (min-width: 1200px) and (max-width:1700px){
  .video{height: 70vh;}
  .bg{height: 130px;}
}

