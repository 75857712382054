*{margin: 0; padding: 0; outline: none; box-sizing: border-box;}
body{background-color: #33c3f1; font-family: 'Gotham Book';}
iframe{width: 100%;height: 56vw}
.container{max-width: 1600px;margin: 0 auto;}
strong, button{font-family: 'Gotham';}
form{text-align: center;}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
  padding-left: 5px;
  text-transform: uppercase;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
  padding-left: 5px;
  text-transform: uppercase;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
  padding-left: 5px;
  text-transform: uppercase;
}

